import React, { lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NavBar from "./components /navigation/NavBar";
import Footer from "./components /footer/CustomFooter";
import Courses from "./components /courses/Courses";
import { ToastContainer } from "react-toastify";
import Spinner from "./components /blog/Spinner";
import PrivateRoute from "./components /navigation/PrivateRoute";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
const Home = lazy(() => import("./components /home/Home"));
const Blog = lazy(() => import("./components /blog/Blog"));
const About = lazy(() => import("./components /about/About"));
const Signup = lazy(() => import("./components /account/Signup"));
const Login = lazy(() => import("./components /account/Login"));
const PostDisplay = lazy(() => import("./components /blog/PostDisplay"));
const ArticleCreation = lazy(() =>
  import("./components /blog/ArticleCreation")
);
const ArticleUpdate = lazy(() => import("./components /blog/ArticleUpdate"));
const BlogDashboard = lazy(() => import("./components /admin/BlogDashboard"));
const Projects = lazy(() => import("./components /portfolio/Projects"));
const ResumePage = lazy(() => import("./components /resume/ResumePage"));
const ContactForm = lazy(() => import("./components /contact/ContactForm"));

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <NavBar isLoggedIn={false} />
        <Suspense fallback={<Spinner />}>
          <ToastContainer autoClose={1000} />
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/" element={<PrivateRoute />}>
              <Route exact path="/article/new" element={<ArticleCreation />} />
              <Route path="/admin/blog/dashboard" element={<BlogDashboard />} />
              <Route
                path="/article/:id/:slug/update"
                element={<ArticleUpdate />}
              />
              <Route
                path="/article/:id/:slug/delete"
                element={<ArticleUpdate />}
              />
              {/* <Route path="/courses/:id/:slug" element={<DashBoard />} />
            <Route path="/courses/:id/:slug/update" element={<DashBoard />} />
            <Route path="/courses/:id/:slug/delete" element={<BlogDashboard />} /> */}
            </Route>
            <Route path="/signup" element={<Signup />} />
            <Route path="/login" element={<Login />} />
            <Route path="/courses" element={<Courses />} />
            <Route path="/blog/articles" element={<Blog />} />
            <Route path="/about" element={<About />} />
            <Route path="/portfolio" element={<Projects />} />
            <Route path="/resume" element={<ResumePage />} />
            <Route path="/contact" element={<ContactForm />} />
            <Route path="/article/:id/:slug" element={<PostDisplay />} />
          </Routes>
        </Suspense>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
