export const ARTICLES_SCHEMA = "articles";
export const COURSES_SCHEMA = "courses";
export const USERS_SCHEMA = "users";
export const ARTICLE_FIELDS = [
  "id",
  "title",
  "summary",
  "slug",
  "image",
  "content",
  "date",
];
export const COURSE_FIELDS = [
  "id",
  "title",
  "summary",
  "slug",
  "image",
  "content",
  "date",
];
export const BLOG_TABLE_HEAD = [
  "Title",
  "Status",
  "Category",
  "Author",
  "Article age",
];
export const ARTICLES_POPULATE_FIELDS = ["image"];
export const COURSE_POPULATE_FIELDS = ["image"];
export const ARTICLES_QUERY = "articles";
export const ARTICLE_QUERY = "article";
export const COURSES_QUERY = "courses";
export const COURSE_QUERY = "course";
export const G_PROVIDER = "google";
export const L_PROVIDER = "local";
export const COURSE_CARD_FOLDER = "/courses/cards";
export const COURSE_CONTENT_FOLDER = "/courses/content";
export const ARTICLE_CARD_FOLDER = "/articles/cards";
export const ARTICLE_CONTENT_FOLDER = "/articles/content";
export const ARTICLES_PER_PAGE = 16;
export const SLUG_FIELD = "slug";
export const IS_EQUAL = "==";
const REACT_APP_FIREBASE_API_KEY = "AIzaSyCV2ZpUxHTgIPwawWCsIqSyOzbIjVS0N2c";
const REACT_APP_FIREBASE_AUTH_DOMAIN = "brainarena-c326b.firebaseapp.com";
const REACT_APP_FIREBASE_PROJECT_ID = "brainarena-c326b";
const REACT_APP_FIREBASE_STORAGE_BUCKET = "brainarena-c326b.appspot.com";
const REACT_APP_FIREBASE_MESSAGE_SENDER_ID = "792635210041";
const REACT_APP_FIREBASE_APP_ID = "1:792635210041:web:6aca57d63c01b39baf50e8";
const REACT_APP_FIREBASE_MEASUREMENT_ID = "G-CBh1E0C73S5";
const REACT_APP_DB = "cf";
const GENERATE_SOURCEMAP = false;
export const REACT_APP_TINYMCE_API_KEY =
  "owzs5fd0fs00paufdmj2jci1sxc18e4ch8eql28wosymeiqi";
export const USER = "user";
export const EMAIL_PUBLIC_KEY = "GoKZQDXG7tIQWcfo1";
export const EMAIL_SERVICE_ID = "service_ln5xb13"
export const EMAIL_TEMPLATE_ID = "template1"
export const APP_CONFIG = Object.freeze({
  REACT_APP_FIREBASE_API_KEY,
  REACT_APP_FIREBASE_AUTH_DOMAIN,
  REACT_APP_FIREBASE_PROJECT_ID,
  REACT_APP_FIREBASE_STORAGE_BUCKET,
  REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
  REACT_APP_FIREBASE_APP_ID,
  REACT_APP_FIREBASE_MEASUREMENT_ID,
  REACT_APP_DB,
  GENERATE_SOURCEMAP,
});
export const CODE_SAMPLE_LANGUAGES = [
  { text: "HTML/XML", value: "markup" },
  { text: "JavaScript", value: "javascript" },
  { text: "CSS", value: "css" },
  { text: "PHP", value: "php" },
  { text: "Ruby", value: "ruby" },
  { text: "Python", value: "python" },
  { text: "Java", value: "java" },
  { text: "Go", value: "Go" },
  { text: "C", value: "c" },
  { text: "C#", value: "csharp" },
  { text: "C++", value: "cpp" },
];
export const TYPING_STRINGS = [
  `<span style="color:#063970; font-size:3.5vw; font-weight:bolder"><Human</span>`,
  `<span style="color:#063970; font-size:3.5vw; font-weight:bolder">CS Student</span>`,
  `<span style="color:#063970; font-size:3.5vw; font-weight:bolder">Software Developer</span>`,
  `<span style="color:#063970; font-size:3.5vw; font-weight:bolder">Tech Enthusiast</span>`,
  `<span style="color:#063970; font-size:3.5vw; font-weight:bolder">Blogger</span>`,
  `<span style="color:#063970; font-size:3.5vw; font-weight:bolder">Self-Proclaimed Chef</span>`,
  `<span style="color:gray; font-size:3.5vw; font-weight:bolder">(Think Ratatouille)</span>`,
];

