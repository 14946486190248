import NavLink from "./NavLink";
import "../../index.css";
import MobileNav from "./MobileNav";
import { useState } from "react";
import Hamburger from "./Hamburger";
import SiteLogo from "./SiteLogo";
import { useAuthUser } from "@react-query-firebase/auth";
import { auth } from "../../firebase";
import { Button } from "flowbite-react";
import { logout } from "../../api/accounts";
import { USER } from "../../constants";

const NavBar = ({ handleSearch, isLoggedIn }) => {
  const [isOpen, setIsOpen] = useState(false);
  const user = useAuthUser([USER], auth);
  
  const toggleOpen = () => {
    return setIsOpen(!isOpen);
  };

  return (
    <header>
      <nav>
        <div>
          <div className=" bg-slate-800 z-50 sticky  top-0 pl-4 pr-4 flex justify-end text-right text-white w-full  h-24">
            <SiteLogo />
            <div className="hidden md:flex justify-end space-x-8 text-right text-white w-full items-center">
              <NavLink text={"Home"} target={"/"} />
              <NavLink text={"About"} target={"/about"} />
              <NavLink text={"Portfolio"} target={"/portfolio"} />
              <NavLink text={"Blog"} target={"/blog/articles"} />
              <NavLink text={"Resume"} target={"/resume"} />
              <NavLink text={"Contact Me"} target={"/contact"} />
              {user.data ? (
                <div className="items-center justify-center ml-4"><Button  color="failure" onClick={()=>logout()}>Logout</Button></div>
                
              ) : (
                <NavLink text={"Login"} target={"/login"} />
              )}
              <NavLink text={""} target={"/search"} />
            </div>
            <Hamburger isOpen={isOpen} toggleOpen={toggleOpen} />
          </div>
          {isOpen && <MobileNav toggleOpen={toggleOpen} isOpen={isOpen} />}
        </div>
      </nav>
    </header>
  );
};

export default NavBar;
