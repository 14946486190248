import { USERS_SCHEMA, G_PROVIDER, L_PROVIDER} from "../constants";
import {
  GoogleAuthProvider,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  sendEmailVerification,
  signOut
} from "firebase/auth";


import { query, getDocs, collection, where, addDoc } from "firebase/firestore";
import { db, auth } from "../firebase";

const googleProvider = new GoogleAuthProvider();
export const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    const q = query(collection(db, USERS_SCHEMA), where("uid", "==", user.uid));
    const docs = await getDocs(q);
    const {last,first} = user.displayName.split(" ")
    if (docs.docs.length === 0) {
      //create new user is user does not exist already
      return await addDoc(collection(db, USERS_SCHEMA), {
        uid: user.uid,
        first: first,
        last: last,
        authProvider: G_PROVIDER,
        email: user.email,
        role:"basic"
      });
    }
    return docs.docs;
  } catch (err) {
    return err.message;
  }
};

export const logInWithEmailAndPassword = async (email, password) => {
  try {
    return await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    return err.message;
  }
};

export const registerWithEmailAndPassword = async (
  email,
  password
) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    const r = await addDoc(collection(db, USERS_SCHEMA), {
      uid: user.uid,
      displayName:user.displayName,
      authProvider: L_PROVIDER,
      email:email,
      
    });
    console.log(r)
    return res
  } catch (err) {
   return err.message
  }
};
export const requestPasswordReset = async (email) => {
  try {
    const res = await sendPasswordResetEmail(auth, email);
    return res;
  } catch (err) {
    return err.message;
  }
};
export const logout = async() => {
  try {
    await signOut(auth);
    return true;
  } catch (error) {
    return false;
  }
  
};

export const requestEmailVerification = async(email) => {
  try {
    await sendEmailVerification(auth,email);
    return true;
  } catch (error) {
    return false;
  }
  
};
