import {

  BsGithub,
} from "react-icons/bs";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="relative, w-screen bottom-0">
      <div className="w-full flex justify-center bottom-0 font-sans z-50 bg-slate-800  fixed h-24 text-slate-500 text-xs items-center">
       <div className="space-x-6  ml-8 relative sm:flex-wrap ">
        <span className="hover:text-white"> Copyright &copy; 2022  </span>
        <Link to={"/terms"}><span className="hover:text-white">Terms & Conditions </span></Link>
        <Link to={"/privacy"}><span className="hover:text-white">Privacy & Policy </span></Link>
        </div>
        <div className=" flex space-x-8 ml-2 relative sm:flex-wrap mr-3 ">
        <a href={"https://github.com/Sylvainyabre"}><span className="hover:text-white" > <BsGithub/> </span></a>
        {/* <Link to={"/contact/facebook"}><span className="hover:text-white"> <BsFacebook/> </span></Link>  */}
        
        </div>
        
      </div>
    </footer>
  );
};

export default Footer;
