// import { BsSearch } from "react-icons/bs";
// import { FaUserAlt } from "react-icons/fa";

// import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import NavLink from "./NavLink";
import { useAuthUser } from "@react-query-firebase/auth";
import { logout } from "../../api/accounts";
import { auth } from "../../firebase";
import { USER } from "../../constants";

const MobileNav = ({ toggleOpen }) => {
  const navigate = useNavigate()
  const user = useAuthUser([USER], auth);
  const logoutAndToggle = () => {
    logout();
    toggleOpen();
  };
  const navigateAndToggle = (link)=>{
    navigate(link);
    toggleOpen();
  }
  return (
    <div className="relative divide-y justify-center text-center block items-center ">
      <ul className=" relative block bg-slate-900 h-screen w-screen text-white list-none justify-center divide-y items-center">
        <li onClick={()=>navigateAndToggle("/")} className=" p-6 items-center">
          <NavLink text={"Home"} target={"#"} LinkIcon={""} />
        </li>
        <li onClick={()=>navigateAndToggle("/about")} className="  p-6 items-center">
          <NavLink text={"About"} target={"#"} LinkIcon={""} />
        </li>
        <li onClick={()=>navigateAndToggle("/portfolio")} className="  p-6 items-center">
          <NavLink text={"Portfolio"} target={"#"} LinkIcon={""} />
        </li>
        <li onClick={()=>navigateAndToggle("/blog/articles")} className=" p-6 items-center">
          <NavLink text={"Blog"} target={"#"} LinkIcon={""} />
        </li>
        <li onClick={()=>navigateAndToggle("/resume")} className=" p-6 items-center">
          <NavLink text={"Resume"} target={"#"} LinkIcon={""} />
        </li>
        <li onClick={()=>navigateAndToggle("/contact")} className=" p-6 items-center">
          <NavLink text={"Contact Me"} target={"#"} LinkIcon={""} />
        </li>
        {user.data ? (
          <li onClick={logoutAndToggle} className=" p-6 bg-red-600 items-center  w-full">
            <NavLink text={"Logout"} target={"#"} LinkIcon={""} />
            
          </li>
        ) : (
          <li onClick={()=>navigateAndToggle("/login")} className=" p-6 items-center w-full">
            <NavLink text={"Login"} target={"#"} LinkIcon={""} />
            
          </li>
        )}
      </ul>
    </div>
  );
};

export default MobileNav;
