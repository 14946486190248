
import { getAnalytics } from "firebase/analytics";
import { getAuth} from "firebase/auth";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage';
import { APP_CONFIG } from "./constants";
const firebaseConfig = {
  apiKey: APP_CONFIG.REACT_APP_FIREBASE_API_KEY,
  authDomain: APP_CONFIG.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: APP_CONFIG.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: APP_CONFIG.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: APP_CONFIG.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
  appId: APP_CONFIG.REACT_APP_FIREBASE_APP_ID,
  measurementId: APP_CONFIG.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
export const analytics = getAnalytics(firebaseApp);
export const db = getFirestore(firebaseApp);
export const auth = getAuth(firebaseApp);
export const firestore = getFirestore(firebaseApp)
export const storage = getStorage(firebaseApp)

