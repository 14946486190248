import React from "react";
import { useLocation, Navigate,Outlet } from "react-router-dom";
import { useAuthUser } from "@react-query-firebase/auth";
import { auth } from "../../firebase";
import Spinner from "../blog/Spinner";
import { USER } from "../../constants";

function PrivateRoute() {
  const user = useAuthUser(USER, auth);
  const location = useLocation()
  if(user.isLoading){
    return  <div className="justify-center  m-auto w-10 mt-32 items-center">
        <Spinner />
      </div>
  }
  else if(user.isError){
    return <div className={"text-red-500"}>Sorry, something went terribly wrong!</div>
  }
  
  return (
    user.data?<Outlet/>:<Navigate to={"/login"} state={{prev:location.pathname}}/>
  );
}

export default PrivateRoute;
