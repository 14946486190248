import React from 'react'
import { FaTimes,FaBars } from 'react-icons/fa'

const Hamburger = ({isOpen,toggleOpen}) => {
  return (
    <div className="md:hidden relative inline-block align-middle top-8 ">
              {isOpen ? (
                <FaTimes className="text-lg text-bold " onClick={toggleOpen} />
              ) : (
                <FaBars
                  className="text-lg text-bold "
                  onClick={toggleOpen}
                />
              )}

              
            </div>
  )
}

export default Hamburger