import React from "react";

const SiteLogo = () => {
  return (
    <div className="absolute left-2 top-2 text-left">
      <img
        src={require("./SylvainLogo.png")}
        alt="Brain Arena logo"
        className="h-20 w-20 rounded-full bg-cover"
      />
    </div>
  );
};

export default SiteLogo;
