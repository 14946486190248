
import { Link } from 'react-router-dom'


const NavLink = ({text,target}) => {
  return (
    <Link className="font-sans text-lg relative hover:text-slate-400" to={target}>{text}</Link>
  )
}

export default NavLink